import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navbar";
import {
  Container,
  CssBaseline,
  TextField,
  Avatar,
  Button,
  Typography,
  Box,
  makeStyles,
  Link,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { UPDATE_PRIZE_URL } from "../../helpers/apiUrl";
import { Redirect } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UpdatePage = ({ props }) => {
  const [redirect, setRedirect] = useState(false);
  const [chance, setChance] = useState(0);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [prizeID, setPrizeID] = useState(0);
  const token = localStorage.getItem("token");
  const classes = useStyles();

  useEffect(() => {
    const { name, value, chance, id } = props.location.state.data;
    const { type } = props.location.state;
    setType(type);
    setName(name);
    setValue(value);
    setChance(chance);
    setPrizeID(id);
  }, []);
  if (redirect) {
    return <Redirect to="/admin"></Redirect>;
  }

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  const handleChangeChance = (e) => {
    setChance(e.target.value);
  };

  const updatePrize = async () => {
    let data;
    if (type === "all") {
      data = {
        chance,
        value,
        name,
      };
    } else if (type === "chance") {
      data = {
        chance,
      };
    } else {
      data = {
        value,
      };
    }

    const response = await fetch(UPDATE_PRIZE_URL.replace(":id", prizeID), {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      alert("error");
    } else {
      setRedirect(true);
    }
  };
  
  return (
    <div>
      <NavigationBar></NavigationBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            UPDATE PRIZES CHANCE
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={name}
              onChange={handleChangeName}
              disabled={type === "all" ? false : true}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="value"
              label="Value"
              type="number"
              id="value"
              value={value}
              onChange={handleChangeValue}
              disabled={type === "all" || type === "value" ? false : true}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="chanec"
              label="Chance"
              type="number"
              id="chance"
              value={chance}
              onChange={handleChangeChance}
              disabled={type === "all" || type === "chance" ? false : true}
            />
            <Button
              // type="submit"
              onClick={updatePrize}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              UPDATE
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

export default UpdatePage;
