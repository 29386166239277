import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./containers/LoginPage";
import RegisterPage from "./containers/RegisterPage";
import AdminPage from "./containers/AdminPage";
import GamePage from "./containers/GamePage";
import UpdatePage from "./containers/UpdateUserPage";
import UpdatePrizePage from "./containers/UpdatePrizePage";
import { connect } from "react-redux";
import { setUserId } from "./redux/actions/userActions";
import { setSnackbar } from "./redux/actions/commonAction";
import SnackbarComponent from "./components/SnackBar";
import { Helmet } from "react-helmet";

const App = (props) => {
  return (
    <div>
      <Helmet>
        <title> Game Kocok Seru</title>
      </Helmet>
      <SnackbarComponent snackbar={props.snackbar} />
      <Router>
        <Switch>
          <Route exact path="/">
            <LoginPage />
          </Route>
          <Route path="/register">
            <RegisterPage />
          </Route>
          <Route path="/game">
            <GamePage />
          </Route>
          <Route path="/admin">
            <AdminPage />
          </Route>
          <Route
            path="/update/:id"
            render={(props) => <UpdatePage props={props} />}
          />
          <Route
            path="/updatePrize/:id"
            render={(props) => <UpdatePrizePage props={props} />}
          />
        </Switch>
      </Router>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    snackbar: state.common.snackbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (userId) => dispatch(setUserId(userId)),
    setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
