import React, { useEffect, useState } from "react";
import {
  CURRENT_USER_URL,
  POST_USER_PRIZE_URL,
  GET_USER_HISTORY,
  GET_USER_HISTORY_BY_DATE,
} from "../../helpers/apiUrl";
import { Redirect } from "react-router-dom";
import gachaball from "../../assets/back-ball.png";
import gachaball3 from "../../assets/back-ball-3.png";
import HeroVending from "../../assets/gacha-machine-hero.png";
import PerangVending from "../../assets/gacha-machine-perang-new.png";
import MesinVending from "../../assets/gacha-4d-complete.png";
import GoldenBall from "../../assets/bola emas 1 copy.png";
import gopeceng from "../../assets/gopeceng.png";
import dualatus from "../../assets/dualatus.png";
import tujuh from "../../assets/tujuhlatustujuhtujuh.png";
import tigalatus from "../../assets/tigalatus.png";
import goceng from "../../assets/Goceng.jpg";
import duapuluhlimaribu from "../../assets/25.jpg";
import goban from "../../assets/50.jpg";
import ceban from "../../assets/ceban.jpg";
import cepekceng from "../../assets/100.jpg";
import nopekceng from "../../assets/200.jpg";
import sapekceng from "../../assets/300.jpg";
import gopekceng from "../../assets/500.jpg";
import tujuhtujuhtujuh from "../../assets/777.jpg";
import tujuhpuluhlimaribu from "../../assets/75.jpg";
import goldenticket from "../../assets/Golden ticket.jpg";
import "./index.scss";
import { connect, useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/actions/commonAction";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, lighten } from "@material-ui/core/styles";
import moment from "moment";
import CloseWindow from "../../assets/close-window-16.png";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";

function getHistoryModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute",
    width: "90%",
    height: "90%",
    overflow: "auto",
    maxWidth: 550,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    maxWidth: 300,
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: "16px",
    paddingLeft: "5px",
    paddingRight: "5px",
  };
}

const Game = (props) => {
  const [historyModalStyle] = useState(getHistoryModalStyle);
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const [user, setUsername] = useState("");
  const [chance, setChance] = useState(0);
  const [prizeData, setPrizeData] = useState({});
  const [animation, setAnimation] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const token = localStorage.getItem("token");
  const [open, setOpen] = React.useState(false);
  const [userHistoryData, setUserHistoryData] = React.useState([]);
  const [openHistoryModal, setOpenHistoryModal] = React.useState(false);
  const [historyPage, setHistoryPage] = useState(0);
  const [historyRowsPerPage, setHistoryRowsPerPage] = React.useState(10);
  const [openPrizeList, setOpenPrizeList] = React.useState(false);
  const [perangbolaImage, setPerangbolaImage] = React.useState(false);
  const [gameType, setGameType] = React.useState("");

  const [startDate, setStartDate] = React.useState(
    new Date(new Date().toDateString())
  );
  const [endDate, setEndDate] = React.useState(
    new Date(new Date().toDateString())
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenHistoryModal = () => {
    getHistoryByUser();
    setOpenHistoryModal(true);
  };

  const handleCloseHistoryModal = () => {
    setOpenHistoryModal(false);
  };

  const handleHistoryChangePage = (event, newPage) => {
    setHistoryPage(newPage);
  };

  const handleHistoryChangeRowsPerPage = (event) => {
    setHistoryRowsPerPage(parseInt(event.target.value, 10));
    setHistoryPage(0);
  };

  const handleClosePrizeList = () => {
    setOpenPrizeList(false);
  };

  const handleOpenPrizeList = () => {
    setOpenPrizeList(true);
  };

  const handleChangeStartDate = (date) => {
    if (date < endDate) {
      setStartDate(date);
    } else {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: "error",
          message: "Start date must be smaller than end date",
        })
      );
    }
  };

  const handleChangeEndDate = (date) => {
    if (date > startDate) {
      setEndDate(date);
    } else {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: "error",
          message: "End date must be greater than starting date",
        })
      );
    }
  };

  const searchByDate = async () => {
    let startDateFormat = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    let endDateFormat = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

    const response = await fetch(
      GET_USER_HISTORY_BY_DATE.replace(":startDate", startDateFormat)
        .replace(":endDate", endDateFormat)
        .replace(":id", props.userId),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    if (!response.ok) {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: data.type,
          message: data.message,
        })
      );
    } else {
      setUserHistoryData(data);
      dispatch(
        setSnackbar({
          boolean: true,
          severity: data.type,
          message: "User Data found",
        })
      );
    }
  };

  const checkUserValid = async () => {
    const response = await fetch(CURRENT_USER_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (data.type === "error") {
      localStorage.removeItem("token");
      setRedirect(true);
    } else {
      setUsername(data.username);
      setChance(data.chance);
      return true;
    }
  };

  const getHistoryByUser = async () => {
    const response = await fetch(
      GET_USER_HISTORY.replace(":id", props.userId),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    if (!response.ok) {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: data.type,
          message: data.message,
        })
      );
    } else {
      setOpenHistoryModal(true);
      setUserHistoryData(data);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token") && props.userId) {
      checkUserValid();
    } else {
      localStorage.clear();
      setRedirect(true);
    }
  }, []);

  useEffect(() => {
    if (chance <= 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [chance]);

  useEffect(() => {
    if (user) {
      if (user.substr(0, 3) === "ag9") {
        setGameType("Perang");
      } else if (user.substr(0, 3) === "M4D") {
        setGameType("Mesin");
      } else {
        setGameType("Hero");
      }
      let audio = new Audio(
        "https://www.mboxdrive.com/y2mate.com%20-%20%E5%85%8E%E7%94%B0%E3%81%BA%E3%81%93%E3%82%89%20Usada%20Pekora%20BGM%201%20HOUR%2060%20FPS.mp3"
      );
      var music = document.createElement("AUDIO");
      document.body.appendChild(music);
      audio.volume = 0.3;
      audio.loop = true;
      audio.src =
      "https://www.mboxdrive.com/y2mate.com%20-%20%E5%85%8E%E7%94%B0%E3%81%BA%E3%81%93%E3%82%89%20Usada%20Pekora%20BGM%201%20HOUR%2060%20FPS.mp3";
      audio.play();
    }
  }, [user]);

  const HistoryTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align={"center"}>Nama Hadiah</TableCell>
          <TableCell align={"center"}>Tanggal Dapat Hadiah</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const PrizeListHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align={"center"}>Nama Hadiah</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { date } = props;

    return (
      <Toolbar
        className={classes.root}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Riwayat user: {user}
        </Typography>
        {date ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="start-date-picker-dialog"
                label="Start Date"
                format="MM/dd/yyyy"
                value={startDate}
                onChange={handleChangeStartDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                id="end-date-picker-dialog"
                label="End Date"
                format="MM/dd/yyyy"
                value={endDate}
                onChange={handleChangeEndDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Button onClick={searchByDate} variant="contained" color="primary">
              Search
            </Button>
          </MuiPickersUtilsProvider>
        ) : (
          <div></div>
        )}
      </Toolbar>
    );
  };

  const roll = async () => {
    setButtonDisabled(true);
    const response = await fetch(CURRENT_USER_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data1 = await response.json();

    if (data1.type === "error") {
      localStorage.removeItem("token");
      setRedirect(true);
    } else {
      setUsername(data1.username);
      setChance(data1.chance);
      if (data1.chance > 0 && !buttonDisabled) {
        const response = await fetch(
          POST_USER_PRIZE_URL.replace(":id", props.userId),
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              chance: data1.chance - 1,
            }),
          }
        );

        const data = await response.json();
        if (data.type === "error") {
          dispatch(
            setSnackbar({
              boolean: true,
              severity: "error",
              message: "Error!",
            })
          );
          localStorage.removeItem("token");
          setRedirect(true);
        } else {
          if (data.user && data.prize) {
            setChance(data.user.chance);
            setAnimation(true);
            setButtonDisabled(true);
            setPrizeData(data.prize);
          }
        }
      }
    }

    // }
  };

  const animationEnd = () => {
    setOpen(true);
    setAnimation(false);
    if (chance <= 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const prizeSource = () => {
    let data;
    switch (prizeData.value) {
      case 5:
        data = goceng;
        break;
      case 10:
        data = ceban;
        break;
      case 25:
        data = duapuluhlimaribu;
        break;
      case 50:
        data = goban;
        break;
      case 75:
        data = tujuhpuluhlimaribu;
        break;
      case 100:
        data = cepekceng;
        break;
      case 200:
        data = nopekceng;
        break;
      case 300:
        data = sapekceng;
        break;
      case 500:
        data = gopekceng;
        break;
      case 777:
        data = tujuhtujuhtujuh;
        break;
      case 10000:
        data = goldenticket;
        break;
      default:
        break;
    }
    return data;
  };

  if (redirect) {
    return <Redirect to="/"></Redirect>;
  }

  const classHeroOrPerang = () => {
    if (gameType === "Perang") {
      return "container game-background-perangbola";
    } else if (gameType === "Hero") {
      return "container game-background";
    } else if (gameType === "Mesin") {
      return "container game-background-mesin4d";
    }
  };

  const imageHeroOrPerang = () => {
    if (gameType === "Perang") {
      return PerangVending;
    } else if (gameType === "Hero") {
      return HeroVending;
    } else if (gameType === "Mesin") {
      return MesinVending;
    }
  };
  return (
    <div className={classHeroOrPerang()}>
      <div className="top-bar">
        <Button variant="contained" className="username-button">
          {user}
        </Button>
        <Button variant="contained" color="secondary" className="chance-button">
          Chance: {chance}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="riwayat-button"
          onClick={handleOpenHistoryModal}
        >
          Riwayat
        </Button>
      </div>
      {/* <div className="second-bar">
        <Button
          variant="contained"
          className="daftar-hadiah-button"
          onClick={handleOpenPrizeList}
        >
          Daftar Hadiah
        </Button>
      </div> */}
      <div>
        <div>
          <div>
            <img
              className="vending-machine"
              src={imageHeroOrPerang()}
              alt="hero-vending-machine"
            />
            <div className="test-ball">
              <div
                onAnimationEnd={animationEnd}
                className={
                  animation ? "gachakon_ball animation-move" : "gachakon_ball"
                }
                style={{ left: 0.4 + "rem", top: 0.46 + "rem" }}
              >
                {" "}
                <img src={dualatus} alt="gacha-ball-1" />
              </div>
              <div
                className={
                  animation ? "gachakon_ball animation-move-2" : "gachakon_ball"
                }
                style={{ left: 3 + "rem", top: 0.46 + "rem" }}
              >
                {" "}
                <img src={gachaball} alt="laptop" />
              </div>
              <div
                className={
                  animation ? "gachakon_ball animation-move" : "gachakon_ball"
                }
                style={{ left: 7 + "rem", top: 0.46 + "rem" }}
              >
                {" "}
                <img src={gachaball3} alt="gacha-ball-3" />
              </div>
              <div
                className={
                  animation ? "gachakon_ball animation-move-2" : "gachakon_ball"
                }
                style={{ left: 6 + "rem", top: 3.1 + "rem" }}
              >
                {" "}
                <img src={tigalatus} alt="gacha-ball-3" />
              </div>
              <div
                className={
                  animation ? "gachakon_ball animation-move" : "gachakon_ball"
                }
                style={{ left: 0.4 + "rem", top: 3.1 + "rem" }}
              >
                {" "}
                <img src={gopeceng} alt="gacha-ball-2" />
              </div>
              <div
                className={
                  animation ? "gachakon_ball animation-move-2" : "gachakon_ball"
                }
                style={{ left: 2.5 + "rem", top: 3.5 + "rem" }}
              >
                {" "}
                <img src={tujuh} alt="gacha-ball" />
              </div>
              <div
                className={
                  animation ? "gachakon_ball animation-move" : "gachakon_ball"
                }
                style={{ left: 4.5 + "rem", top: 2.5 + "rem" }}
              >
                {" "}
                <img src={gopeceng} alt="applewatch" />
              </div>
              <div
                className={
                  animation ? "gachakon_ball animation-move-2" : "gachakon_ball"
                }
                style={{ left: 6 + "rem", top: 0.46 + "rem" }}
              >
                {" "}
                <img src={GoldenBall} alt="airpods" />
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">{prizeData.name}</h2>
            <img alt="prize" src={prizeSource()} className="prize-image" />
            <p id="simple-modal-description">Selamat ya bosku!</p>
          </div>
        </Modal>
        {/* <Modal open={openPrizeList} onClose={handleClosePrizeList}>
          <div style={historyModalStyle} className={classes.paper}>
            <TableContainer>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Daftar hadiah yang bisa didapatkan
              </Typography>
              <Table className={classes.table}>
                <PrizeListHead />
              </Table>
            </TableContainer>
          </div>
        </Modal> */}
        <Modal
          open={openHistoryModal}
          onClose={handleCloseHistoryModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={historyModalStyle} className={classes.paper}>
            <TableContainer style={{ overflow: "auto" }}>
              <div
                // onClick={handleCloseHistoryModal}
                style={{ position: "absolute", left: "94%" }}
              >
                <img
                  src={CloseWindow}
                  onClick={handleCloseHistoryModal}
                  alt="close-window"
                />
              </div>
              <EnhancedTableToolbar date={true} />
              <Table className={classes.table}>
                <HistoryTableHead />
                <TableBody>
                  {userHistoryData
                    .slice(
                      historyPage * historyRowsPerPage,
                      historyPage * historyRowsPerPage + historyRowsPerPage
                    )
                    .map((row) => {
                      return (
                        <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            {moment(row.created_at).format("D-MM-YY h:mm:ss a")}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={userHistoryData.length}
              rowsPerPage={historyRowsPerPage}
              page={historyPage}
              onChangePage={handleHistoryChangePage}
              onChangeRowsPerPage={handleHistoryChangeRowsPerPage}
            />
          </div>
        </Modal>
        <div className="footer">
          <div>
            <Button
              disabled={buttonDisabled}
              variant="contained"
              className="roll-button"
              onClick={roll}
            >
              Roll
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (state) => {
  return {
    userId: state.user.userId,
  };
};

export default connect(mapDispatchToProps)(Game);
