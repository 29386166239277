const baseURL =
  process.env.NODE_ENV === "production"
    ? "http://18.117.88.192/api"
    : "http://localhost:3000/api";
    
const LOGIN_API_URL = `${baseURL}/v1/users/login`;
const REGISTER_API_URL = `${baseURL}/v1/users`;
const CURRENT_USER_URL = `${baseURL}/v1/users/whoami`;
const GET_ALL_USER_URL = `${baseURL}/v1/users`;
const UPDATE_USER_URL = `${baseURL}/v1/users/id/:id`;
const GET_ALL_PRIZE_URL = `${baseURL}/v1/prizes`;
const UPDATE_PRIZE_URL = `${baseURL}/v1/prizes/id/:id`;
const POST_USER_PRIZE_URL = `${baseURL}/v1/usersprizes/id/:id`;
const SEARCH_USERNAME_URL = `${baseURL}/v1/users/username/:username`;
const DELETE_PRIZE_URL = `${baseURL}/v1/prizes/id/:id`;
const ADD_PRIZE_URL = `${baseURL}/v1/prizes/`;
const GET_USER_BY_PAGINATION_URL = `${baseURL}/v1/users/limit?page=:page`;
const GET_USER_HISTORY = `${baseURL}/v1/users/id/:id/history`;
const GET_USER_HISTORY_BY_DATE = `${baseURL}/v1/users/id/:id/history/date?startDate=:startDate&endDate=:endDate`;

export {
  LOGIN_API_URL,
  REGISTER_API_URL,
  CURRENT_USER_URL,
  GET_ALL_USER_URL,
  UPDATE_USER_URL,
  GET_ALL_PRIZE_URL,
  UPDATE_PRIZE_URL,
  POST_USER_PRIZE_URL,
  SEARCH_USERNAME_URL,
  DELETE_PRIZE_URL,
  ADD_PRIZE_URL,
  GET_USER_BY_PAGINATION_URL,
  GET_USER_HISTORY,
  GET_USER_HISTORY_BY_DATE,
};
