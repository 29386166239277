import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navbar";
import {
  Container,
  CssBaseline,
  TextField,
  Avatar,
  Button,
  Typography,
  Box,
  makeStyles,
  Link,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { UPDATE_USER_URL } from "../../helpers/apiUrl";
import { Redirect } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UpdatePage = ({ props }) => {
  const [username, setUsername] = useState("");
  const [userID, setUserID] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [password, setPassword] = useState("");
  const [chance, setChance] = useState(0);
  const [type, setType] = useState("");
  const classes = useStyles();
  const token = localStorage.getItem("token");
  useEffect(() => {
    const { username, chance, id } = props.location.state.data;
    const { type } = props.location.state;
    setType(type);
    setUsername(username);
    setChance(chance);
    setUserID(id);
  }, []);

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleChangeChance = (e) => {
    setChance(e.target.value);
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const updateUser = async () => {
    let data;
    if (type === "all") {
      data = {
        password,
        username,
        chance,
      };
    } else if (type === "password") {
      data = {
        password,
      };
    } else if (type === "chance") {
      data = {
        chance,
      };
    }
    const response = await fetch(UPDATE_USER_URL.replace(":id", userID), {
      method: "PATCH",

      headers: {
        Authorization: `Bearer ${token}`,

        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      alert("error");
    } else {
      setRedirect(true);
    }
  };

  if (redirect) {
    return <Redirect to="/admin"></Redirect>;
  }
  return (
    <div>
      <NavigationBar></NavigationBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            UPDATE USERS CHANCE
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={username}
              onChange={handleChangeUsername}
              disabled={type === "all" ? false : true}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={handleChangePassword}
              disabled={type === "all" || type === "password" ? false : true}
            />
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-label">Chance</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={chance}
                onChange={handleChangeChance}
                disabled={type === "chance" || type === "all" ? false : true}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </Grid>
            <Button
              // type="submit"
              onClick={updateUser}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              UPDATE
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

export default UpdatePage;
