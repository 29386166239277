import "./index.scss";
import React, { useEffect, useState } from "react";
import {
  GET_ALL_USER_URL,
  GET_ALL_PRIZE_URL,
  SEARCH_USERNAME_URL,
  DELETE_PRIZE_URL,
  ADD_PRIZE_URL,
  GET_USER_HISTORY,
  GET_USER_HISTORY_BY_DATE,
} from "../../helpers/apiUrl";
import { withRouter, Redirect } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import GroupIcon from "@material-ui/icons/Group";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import clsx from "clsx";
import TablePagination from "@material-ui/core/TablePagination";
import { setSnackbar } from "../../redux/actions/commonAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute",
    width: "50%",
  };
}

function getHistoryModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute",
    width: "90%",
    height: "90%",
    overflow: "auto",
  };
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    // position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ResponsiveDrawer(props) {
  const [modalStyle] = useState(getModalStyle);
  const [historyModalStyle] = useState(getHistoryModalStyle);
  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [prizesData, setPrizesData] = useState([]);
  const [showUsersData, setShowUsersData] = useState(false);
  const [showPrizesData, setShowPrizesData] = useState(false);
  const [totalChanceData, setTotalChanceData] = useState(0);
  const [prizeName, setPrizeName] = useState("");
  const [prizeChance, setPrizeChance] = useState("");
  const [prizeValue, setPrizeValue] = useState("");
  const [prizeId, setPrizeId] = useState(0);
  const [page, setPage] = useState(0);
  const [historyPage, setHistoryPage] = useState(0);
  const token = localStorage.getItem("token");
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [historyRowsPerPage, setHistoryRowsPerPage] = React.useState(10);
  const [userHistoryData, setUserHistoryData] = React.useState([]);
  const [dense] = React.useState(false);
  const [openHistoryModal, setOpenHistoryModal] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    new Date(new Date().toDateString())
  );
  const [endDate, setEndDate] = React.useState(
    new Date(new Date().toDateString())
  );
  const [userId, setUserId] = React.useState(0);

  const handleChangeStartDate = (date) => {
    if (date < endDate) {
      setStartDate(date);
    } else {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: "error",
          message: "Start date must be smaller than end date",
        })
      );
    }
  };

  const handleChangeEndDate = (date) => {
    if (date > startDate) {
      setEndDate(date);
    } else {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: "error",
          message: "End date must be greater than starting date",
        })
      );
    }
  };
  const rows = [
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Donut", 452, 25.0, 51, 4.9),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
    createData("Honeycomb", 408, 3.2, 87, 6.5),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Jelly Bean", 375, 0.0, 94, 0.0),
    createData("KitKat", 518, 26.0, 65, 7.0),
    createData("Lollipop", 392, 0.2, 98, 0.0),
    createData("Marshmallow", 318, 0, 81, 2.0),
    createData("Nougat", 360, 19.0, 9, 37.0),
    createData("Oreo", 437, 18.0, 63, 4.0),
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleHistoryChangePage = (event, newPage) => {
    setHistoryPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleHistoryChangeRowsPerPage = (event) => {
    setHistoryRowsPerPage(parseInt(event.target.value, 10));
    setHistoryPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    getUserList();
    getPrizeList();
  }, []);

  const getUserList = async () => {
    const response = await fetch(GET_ALL_USER_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      localStorage.clear();
      setRedirect(true);
    } else {
      setUsersData(data);
      setShowUsersData(true);
    }
  };

  const getPrizeList = async () => {
    const response = await fetch(GET_ALL_PRIZE_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      localStorage.clear();
      setRedirect(true);
    } else {
      setPrizesData(data);
      const totalChance = data.reduce((tot, arr) => {
        return tot + Number(arr.chance);
      }, 0);
      setTotalChanceData(totalChance);
    }
  };

  const getHistoryByUser = async (id) => {
    const response = await fetch(GET_USER_HISTORY.replace(":id", id), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: data.type,
          message: data.message,
        })
      );
    } else {
      setOpenHistoryModal(true);
      setUserHistoryData(data);
    }
  };

  const deletePrize = async () => {
    const response = await fetch(DELETE_PRIZE_URL.replace(":id", prizeId), {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (!response.ok) {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: data.type,
          message: data.message,
        })
      );
    } else {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: "success",
          message: "Deleted a prize!",
        })
      );
      setPrizesData(data);
      const totalChance = data.reduce((tot, arr) => {
        return tot + Number(arr.chance);
      }, 0);
      setTotalChanceData(totalChance);
      handleCloseDialog(true);
    }
  };

  const addPrize = async (e) => {
    e.preventDefault();
    const response = await fetch(ADD_PRIZE_URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: prizeName,
        chance: prizeChance,
        value: prizeValue,
      }),
    });

    const data = await response.json();
    if (!response.ok) {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: data.type,
          message: data.message,
        })
      );
    } else {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: "success",
          message: "Added a prize!",
        })
      );
      setPrizesData(data);
      const totalChance = data.reduce((tot, arr) => {
        return tot + Number(arr.chance);
      }, 0);
      setTotalChanceData(totalChance);
      handleClose(true);
    }
  };

  const searchByDate = async () => {
    let startDateFormat = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    let endDateFormat = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

    const response = await fetch(
      GET_USER_HISTORY_BY_DATE.replace(":startDate", startDateFormat)
        .replace(":endDate", endDateFormat)
        .replace(":id", userId),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    if (!response.ok) {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: data.type,
          message: data.message,
        })
      );
    } else {
      setUserHistoryData(data);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenHistory = (data) => {
    getHistoryByUser(data.id);
    setUserId(data.id);
    // console.log(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseHistoryModal = () => {
    setOpenHistoryModal(false);
  };

  const searchUsername = async () => {
    const response = await fetch(
      SEARCH_USERNAME_URL.replace(":username", username),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: data.type,
          message: data.message,
        })
      );
    } else {
      dispatch(
        setSnackbar({
          boolean: true,
          severity: "success",
          message: "Found!",
        })
      );
      setShowUsersData(true);
      setUsersData([data]);
      setUsername("");
    }
  };

  const handleClikShowUserData = () => {
    getUserList();
    setShowUsersData(true);
    setShowPrizesData(false);
  };

  const handleClickShowPrizeData = () => {
    setShowUsersData(false);
    setShowPrizesData(true);
  };

  const handleLogOut = () => {
    localStorage.clear();
    setRedirect(true);
  };

  const RedirectUpdateUserPage = (data, type) => {
    props.history.push(`/update/${data.id}`, {
      data,
      type,
    });
  };

  const RedirectUpdatePrizePage = (data, type) => {
    props.history.push(`/updatePrize/${data.id}`, {
      data,
      type,
    });
  };

  const onSearchUsername = (e) => {
    e.preventDefault();

    if (username === "") {
      return;
    } else {
      searchUsername();
    }
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePrizeName = (e) => {
    setPrizeName(e.target.value);
  };

  const handleChangePrizeValue = (e) => {
    setPrizeValue(e.target.value);
  };

  const handleChangePrizeChance = (e) => {
    setPrizeChance(e.target.value);
  };

  const handleCloseDialog = () => {
    setDialog(false);
  };

  const handleOpenDialog = (id) => {
    setDialog(true);
    setPrizeId(id);
  };
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell align={"left"}>Username</TableCell>
          <TableCell align={"right"}>Role</TableCell>
          <TableCell align={"right"}>Chance</TableCell>
          <TableCell colSpan={2} align={"center"}>
            UPDATES
          </TableCell>
          <TableCell align={"right"} style={{ fontSize: "30px" }}>
            History
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const HistoryTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align={"left"}>Username</TableCell>
          <TableCell align={"right"}>Prize Name</TableCell>
          <TableCell align={"right"}>Chance</TableCell>
          <TableCell align={"right"}>Value</TableCell>
          <TableCell align={"right"}>Created At</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, tableName, date } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableName}
        </Typography>
        {date ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="start-date-picker-dialog"
                label="Start Date"
                format="MM/dd/yyyy"
                value={startDate}
                onChange={handleChangeStartDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                id="end-date-picker-dialog"
                label="End Date"
                format="MM/dd/yyyy"
                value={endDate}
                onChange={handleChangeEndDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Button onClick={searchByDate}>Search</Button>
          </MuiPickersUtilsProvider>
        ) : (
          <div></div>
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    tableName: PropTypes.string.isRequired,
  };

  const userOrPrizeList = () => {
    if (showUsersData) {
      return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(usersData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <TableCell component="th" id={labelId} scope="row">
                            {row.username}
                          </TableCell>
                          <TableCell align="right">{row.role}</TableCell>
                          <TableCell align="right">{row.chance}</TableCell>
                          <TableCell
                            align="center"
                            onClick={() =>
                              RedirectUpdateUserPage(row, "chance")
                            }
                          >
                            <Button color="primary" variant="contained">
                              Update Chance
                            </Button>
                          </TableCell>
                          <TableCell
                            align="center"
                            onClick={() => RedirectUpdateUserPage(row, "all")}
                          >
                            <Button color="primary" variant="contained">
                              Update All
                            </Button>
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() => handleOpenHistory(row)}
                          >
                            <Button color="primary" variant="outlined">
                              History
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      );
    } else if (showPrizesData) {
      return (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Value</TableCell>
                <TableCell align="right">Chance</TableCell>
                <TableCell colSpan={3} style={{ textAlign: "center" }}>
                  {" "}
                  Update
                </TableCell>
                <TableCell colSpan={1}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prizesData.map((data, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {data.name}
                  </TableCell>
                  <TableCell align="right">{data.value}</TableCell>
                  <TableCell align="right">{data.chance}</TableCell>
                  <TableCell
                    align="right"
                    onClick={() => RedirectUpdatePrizePage(data, "chance")}
                  >
                    <Button color="primary" variant="contained">
                      Update Chance
                    </Button>
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => RedirectUpdatePrizePage(data, "value")}
                  >
                    <Button color="primary" variant="contained">
                      Update Value
                    </Button>
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => RedirectUpdatePrizePage(data, "all")}
                  >
                    <Button color="primary" variant="contained">
                      Update All
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Chip
                      label="Delete"
                      clickable
                      onClick={() => handleOpenDialog(data.id)}
                      color="secondary"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <div>Chance percentage: {totalChanceData}</div>
          </Table>
        </TableContainer>
      );
    }
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItem button onClick={handleClikShowUserData}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={"User List"} />
        </ListItem>
        <ListItem button onClick={handleClickShowPrizeData}>
          <ListItemIcon>
            <CardGiftcardIcon />
          </ListItemIcon>
          <ListItemText primary={"Prize"} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={onSearchUsername}
          >
            <TextField
              id="outlined-basic"
              label="Username"
              variant="outlined"
              value={username}
              onChange={handleChangeUsername}
            />
          </form>
        </ListItem>
        <ListItem button onClick={handleOpen}>
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary={"Add Prize"}></ListItemText>
        </ListItem>
        <Divider />
        <ListItem button onClick={handleLogOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={"Log Out"} />
        </ListItem>
      </List>

      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (redirect) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Admin Gachasystem Herobola
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container>
          <Grid item xs={12}>
            {userOrPrizeList()}
          </Grid>
        </Grid>
        <Dialog
          fullScreen={fullScreen}
          open={dialog}
          onClose={handleCloseDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Are you sure you want to delete this prize?
          </DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialog} color="primary">
              No
            </Button>
            <Button onClick={deletePrize} color="secondary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Add Prize</h2>
            <form onSubmit={addPrize}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                value={prizeName}
                onChange={handleChangePrizeName}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="value"
                label="Value"
                type="number"
                id="value"
                value={prizeValue}
                onChange={handleChangePrizeValue}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="chance"
                label="Chance"
                type="number"
                id="chance"
                value={prizeChance}
                onChange={handleChangePrizeChance}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>
          </div>
        </Modal>
        <Modal
          open={openHistoryModal}
          onClose={handleCloseHistoryModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={historyModalStyle} className={classes.paper}>
            <TableContainer style={{ overflow: "auto" }}>
              <EnhancedTableToolbar
                tableName={"User Prize History"}
                date={true}
              />
              <Table className={classes.table}>
                <HistoryTableHead />
                <TableBody>
                  {stableSort(userHistoryData, getComparator(order, orderBy))
                    .slice(
                      historyPage * historyRowsPerPage,
                      historyPage * historyRowsPerPage + historyRowsPerPage
                    )
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell component="th" id={labelId} scope="row">
                            {row.username}
                          </TableCell>
                          <TableCell align="right">{row.name}</TableCell>
                          <TableCell align="right">{row.chance}</TableCell>
                          <TableCell align="right">{row.value}</TableCell>
                          <TableCell align="right">
                            {moment(row.created_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userHistoryData.length}
              rowsPerPage={historyRowsPerPage}
              page={historyPage}
              onChangePage={handleHistoryChangePage}
              onChangeRowsPerPage={handleHistoryChangeRowsPerPage}
            />
          </div>
        </Modal>
      </main>
    </div>
  );
}

export default withRouter(ResponsiveDrawer);
