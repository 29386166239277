const initialState = {
  snackbar: {
    boolean: false,
    severity: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `SET_SNACKBAR`: {
      return {
        ...state,
        snackbar: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
