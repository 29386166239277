import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import HerobolaLogo from "../../assets/herobola-logo2.png";
import Mesin4DLogo from '../../assets/mesin4d-logo.png';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "./index.scss";
import { LOGIN_API_URL } from "../../helpers/apiUrl";
import { Redirect } from "react-router";
import USER from "../../constants/index.js";
import { connect, useDispatch } from "react-redux";
import { setUserId } from "../../redux/actions/userActions";
import { setSnackbar } from "../../redux/actions/commonAction";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://192.53.114.253/">
        HEROTEAM
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirectGame, setRedirectGame] = useState(false);
  const [redirectAdmin, setRedirectAdmin] = useState(false);

  const onLogin = async (e) => {
    e.preventDefault();
    const response = await fetch(LOGIN_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    const data = await response.json();
    if (!response.ok) {
      if (data.type === "error") {
        dispatch(
          setSnackbar({
            boolean: true,
            severity: data.type,
            message: data.message,
          })
        );
      }
    } else {
      localStorage.setItem("token", data.token);
      props.setUserId(data.id);
      dispatch(
        setSnackbar({
          boolean: true,
          severity: "success",
          message: "Sukses Login!",
        })
      );
      if (data.role === USER.USER) {
        setRedirectGame(true);
      } else if (data.role === USER.ADMIN) {
        setRedirectAdmin(true);
      }
    }
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  if (redirectGame) {
    return <Redirect to="/game"></Redirect>;
  } else if (redirectAdmin) {
    return <Redirect to="/admin"></Redirect>;
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}> */}
          <div>
            <img
              src={HerobolaLogo}
              style={{ width: "300px" }}
              alt="herobola-logo2"
            ></img>
          </div>
          <div>
            <img
              src={Mesin4DLogo}
              style={{ width: "300px" }}
              alt="mesin4d-logo2"
            ></img>
          </div>
          {/* </Avatar> */}
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} noValidate onSubmit={onLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={handleChangeUsername}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handleChangePassword}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserId: (userId) => dispatch(setUserId(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
