import { combineReducers } from "redux";
import userReducer from "./userReducer";
import snackbarReducer from "./commonReducer";

const rootReducer = combineReducers({
  user: userReducer,
  common: snackbarReducer,
});

export default rootReducer;
