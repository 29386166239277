import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../redux/actions/commonAction";
import React from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const SnackBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state) => state.common);

  function handleClose() {
    dispatch(
      setSnackbar({
        snackbar: {
          boolean: false,
          // severity: "",
          // message: "",
        },
      })
    );
  }

  return (
    <div>
      {snackbar.boolean ? (
        <div className={classes.root}>
          <Snackbar
            open={snackbar.boolean}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    // snackbar: state.common.snackbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackbar: (snackbar) => dispatch(setSnackbar(snackbar)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
